<script setup lang = "ts">
import type { Link } from '~/@types/UI'

const drawerActive = ref(false)
const route = useRoute()
const activePath = ref()

const SidebarStore = useSidebarStore()
const AuthStore = useAuthStore()
const { isSidebarOpen, status } = storeToRefs(SidebarStore)
const { isAuthenticated, isPremiumUser } = storeToRefs(AuthStore)
watchEffect(() => {
  activePath.value = `/${route.path.split('/')[1]}`
  drawerActive.value = false
})

// const subscribeLink = computed(() => {

// })

const userLink = computed(() => {
  if (!isAuthenticated.value) {
    return {
      label: 'Login',
      to: '/user/login',
      icon: 'i-material-symbols-login',
    }
  }
  if (!isPremiumUser) {
    return {
      label: 'Subscribe',
      icon: 'i-heroicons-currency-dollar',
      to: '/subscription',
    }
  }
  return {
    label: 'Profile',
    to: '/user/profile',
    icon: 'i-line-md-account',
  }
})

const links = computed<Link[]>(() => {
  return [
    {
      label: 'ADP',
      icon: 'i-mdi-chart-bell-curve',
      to: '/adp/',
      children: [
        {
          label: 'Startup ADP',
          description: 'Average Draft Position for Startup Drafts',
          icon: 'i-mdi-chart-bell-curve',
          to: '/adp/startup',
        },
        // {
        //   label: 'SFB ADP',
        //   description: 'Average Draft Position for Scott Fish Bowl',
        //   icon: 'i-mdi-chart-bell-curve',
        //   to: '/adp/sfb',
        // },
        // {
        //   label: 'SFB Rosters',
        //   description: 'Roster ADP data for Scott Fish Bowl',
        //   icon: 'i-material-symbols-account-tree-outline',
        //   to: '/adp/sfb_rosters',
        // },
        {
          label: 'Rookie ADP',
          description: 'Average Draft Position for Rookie Drafts',
          icon: 'i-mdi-baby-face-outline',
          to: '/adp/rookie',
        },
        {
          label: 'Rookie ADP Odds',
          description: 'Rookie Draft player availability odds',
          icon: 'i-mdi-rain-chance-outline',
          to: '/adp/rookie_heatmap',
        },
        {
          label: 'Draft Companion',
          description: 'Draft Companion',
          icon: 'i-material-symbols-troubleshoot',
          badge: 'Premium',
          to: '/adp/companion',
        },
        // {
        //   label: 'Mock Draft',
        //   description: 'Create a Mock Draft',
        //   icon: 'i-material-symbols-troubleshoot',
        //   // to: '/adp/draft',
        // },
        {
          label: 'Compare ADP',
          description: 'Comprare ADP between different players',
          icon: 'i-mdi-compare',
          to: '/adp/compare',
        },
      ],
    },
    {
      label: 'Trades',
      icon: 'i-heroicons-scale',
      to: '/trades/player',
      children: [
        {
          label: 'Player Trades',
          description: 'Find Trades Involving a Player',
          icon: 'i-heroicons-scale',
          to: '/trades/player',
        },
        {
          label: 'User Trades',
          description: 'Find Trades from any Sleeper User',
          icon: 'i-material-symbols-partner-exchange',
          to: '/trades/user',
          badge: 'Premium',
        },
        {
          label: 'Rostership',
          description: 'Find player Shares from any Sleeper User',
          icon: 'i-material-symbols-account-tree-outline',
          to: '/trades/rostership',
        },
        // {
        //   label: 'Rostership',
        //   description: 'Find Rostership % from any Sleeper User',
        //   icon: 'i-material-symbols-groups',
        //   to: '/trades/rostership',
        //   badge: 'Pending',
        // },
      ],
    },
    {
      label: 'Lineups',
      icon: 'i-mdi-strategy',
      to: '/rosters',

    },
    {
      label: 'Rankings',
      icon: 'i-material-symbols-social-leaderboard-outline',
      to: '/rankings',
      // children: [
      //   {
      //     label: 'Personal Rankings',
      //     description: 'Create new Rankings',
      //     icon: 'i-material-symbols-social-leaderboard-outline',
      //     to: '/rankings',
      //   },
      //   {
      //     label: 'Public Rankings',
      //     description: 'Public Rankings for Dynasty Fantasy Football',
      //     to: '/rankings/view',
      //     icon: 'i-material-symbols-flowsheet-outline',
      //   },
      // ],
    },
    {
      label: 'BestBall',
      icon: 'i-mdi-dog-side',
      to: '/bestball/',
      children: [
        {
          label: 'ADP DK',
          description: 'Average Draft Position for DraftKings Drafts',
          icon: 'i-heroicons-scale',
          to: '/bestball/draftkings/adp',
        },
        {
          label: 'ADP UD',
          description: 'Average Draft Position for Underdog Drafts',
          icon: 'i-heroicons-scale',
          to: '/bestball/underdog/adp',
        },
        {
          label: 'Rostership',
          description: 'Display Player Shares from Underdog Contests',
          icon: 'i-material-symbols-account-tree-outline',
          badge: 'Premium',
          to: '/bestball/underdog/rostership',
        },
        {
          label: 'Stacking Helper',
          description: 'Display Weekly games and Player Exposure for Stacking',
          icon: 'i-mdi-stack-overflow',
          badge: 'Premium',
          to: '/bestball/underdog/rostership/stacks',
        },
        {
          label: 'PlayerGrid',
          description: 'Display NFL Rosters for Underdog Contests',
          icon: 'i-material-symbols-groups-2-outline-rounded',
          badge: 'Premium',
          to: '/bestball/underdog/rosterGrid',
        },
      ],
    },
    ...(userLink.value ? [userLink.value] : []),
    {
      label: 'About',
      icon: 'i-mdi-information-variant-circle-outline',
      to: '/about',
      children: [
        {
          label: 'Posts',
          description: 'Blog Posts',
          icon: 'i-mdi-book-heart-outline',
          to: '/blog',
        },
        {
          label: 'About',
          description: 'About this page',
          icon: 'i-mdi-information-variant',
          to: '/about',
        },
        {
          label: 'Changelog',
          description: 'Follow the latest updates happening on the Site.',
          icon: 'i-material-symbols-release-alert-outline',
          to: '/releases',
        },
      ],
    },
  ]
})

// const statsLink = computed(() => {
//   return {
//     label: 'Stats',
//     icon: 'i-heroicons-presentation-chart-bar-solid',
//     children: [
//       {
//         label: 'Player Stats',
//         description: 'Seasonal metrics for all Players',
//         icon: 'i-heroicons-presentation-chart-bar-solid',
//         to: '/stats/player',
//         badge: 'Pending',
//       },
//       {
//         label: 'Rookie Stats',
//         description: 'Metrics for Rookie Seasons of Players',
//         icon: 'i-material-symbols-account-child-invert',
//         to: '/stats/rookie',
//         badge: 'Pending',
//       },
//       {
//         label: 'Team Stats',
//         description: 'Team Yearly Stats for all Teams',
//         icon: 'i-material-symbols-groups',
//         to: '/stats/team',
//         badge: 'Pending',
//       },
//     ],
//   }
// })
</script>

<template>
  <UHeader :links="links" :ui="{ wrapper: 'bg-background/80' }">
    <template #left>
      <UButton
        :class="{ invisible: !status }"
        :disabled="!status"
        :icon="isSidebarOpen ? 'i-line-md-menu-fold-left' : 'i-line-md-menu-unfold-right'"
        size="xl"
        color="white"
        variant="ghost"
        :ui="{ square: { xl: 'p-1' } }"
        @click="SidebarStore.toggleSideBar"
      />
      <NuxtLink to="/" aria-label="Logo" class="flex flex-shrink-0 items-end gap-1.5 text-xl font-bold text-gray-900 dark:text-white">
        <!-- <TextLogo /> -->
        <span class="font-orbitron text-primary">Dynasty Data Lab</span>
        <!-- <NuxtImg class="invisible " src="/assets/headerLogo.webp" width="80px" height="42px" placeholder loading="lazy" alt="Home" /> -->
      </NuxtLink>
    </template>
    <template #panel>
      <UNavigationTree :links="links" :default-open="true" />
    </template>
    <template #right>
      <NavBarAuth />
    </template>
  </UHeader>
</template>
