export default defineAppConfig({
  ui: {
    primary: 'violet',
    gray: 'cool',
    button: {
      primary: {
        color: 'white',
        variant: 'solid',
      },
      secondary: {
        color: 'gray',
        variant: 'ghost',
      },
    },
    notifications: {
      position: 'top-0 bottom-auto',
    },
    variables: {
      light: {
        background: '255 255 255',
        foreground: 'var(--color-gray-700)',
      },
      dark: {
        background: 'var(--color-gray-950)',
        foreground: 'var(--color-gray-200)',
      },
      header: {
        height: '42px',
      },
      // footer: {
      //   height: '24px',
      // },
    },
    icons: {
      dark: 'i-heroicons-moon-20-solid',
      light: 'i-heroicons-sun-20-solid',
      system: 'i-heroicons-computer-desktop-20-solid',
      search: 'i-heroicons-magnifying-glass-20-solid',
      external: 'i-heroicons-arrow-up-right-20-solid',
      chevron: 'i-heroicons-chevron-down-20-solid',
      hash: 'i-heroicons-hashtag-20-solid',
      menu: 'i-heroicons-bars-3-20-solid',
      close: 'i-heroicons-x-mark-20-solid',
      check: 'i-heroicons-check-circle-20-solid',
    },
    table: {
      default: {
        sortButton: {
          icon: '',
          class: 'font-bold',
          // padded: 'false',
        },
      },
    },
    presets: {
      button: {
        primary: {
          color: 'white',
          variant: 'solid',
        },
        secondary: {
          color: 'gray',
          variant: 'ghost',
        },
        input: {
          color: 'white',
          variant: 'outline',
          ui: {
            font: '',
            color: {
              white: {
                outline: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:ring-gray-300 dark:hover:ring-gray-700 hover:bg-gray-100/50 dark:hover:bg-gray-800/50 text-gray-400 dark:text-gray-500 hover:text-gray-700 dark:hover:text-gray-200 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
              },
            },
          },
        },
      },
    },
  },
})
