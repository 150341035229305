import chroma from 'chroma-js'
import MetricsSettings from '~/assets/json/metrics.json'

export const MapDictionary = {} as { [key: string]: Map<any, any> }

function regMap<T, K>(name: string, data: MapEntry<T, K>[]) {
  MapDictionary[name] = new Map(data)
}

regMap<string, iMetricSetting>('metrics', MetricsSettings.map(setting => [setting.field, setting as iMetricSetting]))

regMap<string, object>('bpp_cluster', [
  ['Unranked', { color: '#CCCCCC', colorValue: 'gray', shade: '500' }],
  ['All-Pro', { qb: '22+', rb: '20+', wr: '18+', te: '16+', color: '#B6D7A8', colorValue: 'green', shade: '500' }],
  ['All-Star', { qb: '20-22', rb: '18-20', wr: '16-18', te: '14-16', color: '#DBDEA1', colorValue: 'green', shade: '300' }],
  ['Role Player', { qb: '18-20', rb: '16-20', wr: '14-16', te: '12-14', color: '#FFE599', colorValue: 'yellow', shade: '500' }],
  ['High Variance', { qb: '15-20', rb: '12-20', wr: '12-18', te: '10-16', color: '#F5BF99', colorValue: 'orange', shade: '300' }],
  ['Bench Warmer', { qb: '0-15', rb: '0-14', wr: '0-14', te: '0-12', color: '#F5BF99', colorValue: 'orange', shade: '300' }],
  ['Other', { color: '#EA9999', colorValue: 'red', shade: '500' }],
  ['Unranked', { color: '#CCCCCC', colorValue: 'gray', shade: '500' }],
])

regMap<string, object>('bpp_action', [
  ['Unranked', { color: '#CCCCCC', colorValue: 'gray', shade: '500' }],
  ['Overpriced-', { color: '#EA9999', colorValue: 'red', shade: '500' }],
  ['Overpriced', { color: '#F5BF99', colorValue: 'orange', shade: '300' }],
  ['Hold', { color: '#FFE599', colorValue: 'yellow', shade: '500' }],
  ['Underpriced', { color: '#DBDEA1', colorValue: 'green', shade: '300' }],
  ['Underpriced+', { color: '#B6D7A8', colorValue: 'green', shade: '500' }],
  ['Unranked', { color: '#CCCCCC', colorValue: 'gray', shade: '500' }],
])

export function genColorScale(colors: string[], total: number = 100): MapEntry<number, number | [number, number, number]>[] {
  const colorScale = chroma.scale(colors).mode('lch')
  return Array.from({ length: (total + 1) }, (v, i) => [i, colorScale(i / total).rgb()])
}

function regColorScale(name: string, colors: string[]) {
  const colorData = genColorScale(colors)
  regMap<number, number | [number, number, number]>(name, colorData)
  regMap(`${name}Rev`, [...colorData].reverse())
}

MapDictionary.BbAdpValues = createInterpolatedMap([
  { num: 1, value: 160 },
  { num: 12, value: 120 },
  { num: 44, value: 80 },
  { num: 98, value: 40 },
  { num: 147, value: 16 },
  { num: 240, value: 1 },
], { extrapolate: true, precision: 1 })

regColorScale('percentileColor', ['#CD2624', '#FD9701', '#FFD000', '#3BAE24', '#0C5EA0'])
regColorScale('rostershipColor', ['#e5a466', '#1f9e89', '#6ece58'])
regColorScale('heatColor', ['#9C5554', '#A66923', '#548849'])

regMap<string, object>('prospect_bpp_grade', [
  ['Unranked', { color: '#CCCCCC', colorValue: 'gray', shade: '500' }],
  ['Transcendent', { color: '#A4C2F4', colorValue: 'blue', shade: '500' }],
  ['Generational', { color: '#B4A7D6', colorValue: 'purple', shade: '500' }],
  ['BPP', { color: '#B6D7A8', colorValue: 'green', shade: '500' }],
  ['Coinflip', { color: '#FFE599', colorValue: 'yellow', shade: '500' }],
  ['Longshot', { color: '#F9CB9C', colorValue: 'orange', shade: '300' }],
  ['Bust', { color: '#EA9999', colorValue: 'red', shade: '500' }],
])

regMap<string, object>('id_pos', [
  ['QB', { color: '#B6D7A8', intenseColor: '#93c47d', name: 'QB' }],
  ['RB', { color: '#EA9999', intenseColor: '#e06666', name: 'RB' }],
  ['WR', { color: '#FFE599', intenseColor: '#ffd966', name: 'WR' }],
  ['TE', { color: '#9FC5E8', intenseColor: '#6fa8dc', name: 'TE' }],
  ['PK', { color: '#9FA1E8', intenseColor: '#8e7cc3', name: 'PK' }],
  ['K', { color: '#cccccc', intenseColor: '#cccccc', name: 'K' }],
  ['DPK', { color: '#cccccc', intenseColor: '#cccccc', name: 'DPK' }],
  [
    'FuturePK',
    {
      color: ['#9FA1E8', '#B5B7ED', '#D0D1F4', '#EBEBFA'],
      intenseColor: ['#8e7cc3', '#a491c3', '#bda1d6', '#d4b3e8'],
      name: 'PK',
    },
  ],
])

regMap<string, string>('trendicons', [
  ['epositive', 'i-material-symbols-trending-up'],
  ['positive', 'i-material-symbols-trending-up'],
  ['neutral', 'i-material-symbols-trending-flat'],
  ['negative', 'i-material-symbols-trending-down'],
  ['enegative', 'i-material-symbols-trending-down'],
])

const baseRostership = new Map([
  ['Unranked', { min: 0, max: 0, color: '#FFFFFF' }],
  ['All In', { min: 80, max: 101, color: '#E99999' }],
  ['Danger', { min: 60, max: 80, color: '#E99999' }],
  ['Overconfident', { min: 45, max: 60, color: '#F5BF99' }],
  ['Aggressive', { min: 30, max: 45, color: '#D7B29E' }],
  ['Balanced', { min: 20, max: 30, color: '#BCD7A6' }],
  ['Cautious', { min: 10, max: 20, color: '#DBDEA1' }],
  ['Conservative', { min: 0, max: 10, color: '#FFE599' }],
])

regMap(
  'rostership',
  [...baseRostership].map(([name, roster]) => [
    name,
    {
      ...roster,
      name,
      tip: `${roster.min}% - ${roster.max === 101 ? '100' : roster.max}% exposure`,
    },
  ]),
)

regMap(
  'rostershipsmall',
  [...baseRostership].map(([name, roster]) => [
    name,
    {
      ...roster,
      name: roster.min === roster.max ? 'Unknown' : `${roster.min}%-${roster.max === 101 ? '100' : roster.max}%`,
    },
  ]),
)

export default MapDictionary
// export default MapDictionary as unknown as iMapDictionary
