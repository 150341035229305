const defaultValues = new Map<iSidebarFilterInputType, any>([
  ['select', []],
  ['position', { QB: false, RB: false, WR: false, TE: false, PK: false }],
  ['slider', [0, 100]],
  ['player', []],
  ['multiselect', []],
])

function getSidebarMetricsConfig(metrics: string[]) {
  return metrics.map((item) => {
    const metric = MapDictionary.metrics.get(item)
    if (!metric)
      return { input: 'custom' }
    const defaultValue = defaultValues.get(metric.inputFilter)
    return { ...metric, defaultValue }
  })
}
const bppSidebarSettings = new Map<iSidebarInputType, iSidebarSettings>([
  ['startup', {
    name: 'adp',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      // 'configAdp',
      // 'draftFilter',
      'id_name',
      'id_pos',
      'phy_age',
      'draft_year',
      'adp_adp',
      'draft_round',
      'clean',
    ]),
  }],
  ['sfb', {
    name: 'sfb',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      // 'configAdp',
      // 'draftFilter',
      'id_name',
      'id_pos',
      'phy_age',
      'draft_year',
      'adp_adp',
      'draft_round',
      'clean',
    ]),
  }],
  ['rookie', {
    name: 'adp',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      // 'configAdp',
      // 'draftFilter',
      'id_name',
      'id_pos',
      'phy_age',
      'adp_adp',
      'draft_round',
      'clean',
    ]),
  }],
  ['rankings', {
    name: 'rankings',
    filterType: 'rankBoard',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'phy_age',
      'draft_year',
      'tier',
      'cluster',
      'action',
      'adp_adp',
      'draft_round',
      'clean',
    ]),
  }],
  ['tradePlayer', {
    name: 'trade',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'roster_starters',
      'total_rosters',
      'scoring_pass_td',
      'scoring_rec',
      'scoring_bonus_rec_te',
      'clean',
    ]),
  }],
  ['tradeUser', {
    name: 'trade',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'roster_starters',
      'total_rosters',
      'scoring_pass_td',
      'scoring_rec',
      'scoring_bonus_rec_te',
      'clean',
    ]),
  }],
  ['rostership', {
    name: 'rostership',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'id_team',
      'roster_count',
      'roster_per',
      'adp_adp',
      'phy_age',
      'draft_year',
      'draft_round',
      'clean',
    ]),
  }],
  ['underdogAdp', {
    name: 'underdogAdp',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'id_team',
      'ud_adp',
      'ud_projection',
      'ud_projection_ppg',
      'phy_age',
      'draft_year',
      'draft_round',
      'clean',
    ]),
  }],
  ['underdogRostership', {
    name: 'underdogRostership',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'id_team',
      'roster_count',
      'roster_per',
      'ud_adp',
      'ud_projection',
      'ud_projection_ppg',
      'phy_age',
      'draft_year',
      'draft_round',
      'clean',
    ]),
  }],
  ['adpComp', {
    name: 'adp',
    filterType: 'aggrid',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'adp_adp',
      'adp_adp_diff',
      'adp_rank',
      'adp_rank_diff',
      'adp_posrank',
      'adp_posrank_diff',
      'clean',
    ]),
  }],
  ['draft', {
    name: 'draft',
    filterType: 'board',
    metrics: getSidebarMetricsConfig([
      'id_name',
      'id_pos',
      'adp_adp',
      'phy_age',
      'adp_rank',
      'adp_posrank',
      'clean',
    ]),
  }],
  ['empty', { name: 'empty', metrics: [], filterType: 'empty' }],
])

export default bppSidebarSettings
