import type { Session, User } from '@supabase/supabase-js'

export const useAuthStore = defineStore({
  id: 'AuthStore',
  state: (): iAuthStoreState => ({
    modalState: false,
    userId: undefined,
    sleeperId: undefined,
    username: undefined,
    email: undefined,
    session: undefined,
    level: 0,
    refreshDate: new Date(),
    token: undefined,
  }),
  persist: {
    pick: ['userId', 'sleeperId', 'username', 'email'],
  },
  getters: {
    isPremiumUser: (state): boolean => state.level > 1,
    getAuthString: (state): string => getRoleFromlevel(state.level),
    getAuthHeader: (state): { Authorization?: string } => state?.token ? { Authorization: `Bearer ${state?.token}` } : {},
    isAuthenticated: (state): boolean => !!state?.token,
    isEliteUser: (state): boolean => (state.level || 0) >= 5,
  },
  actions: {
    // async getUserClaim() {
    //   const { data } = await fetchUserClaim()
    //   this.$patch({ level: getLevelFromRole(data) })
    // },
    async signOut() {
      try {
        this.parseNoSession()
        const { error } = await useSupabaseClient().auth.signOut()
        if (error)
          throw error
      }
      catch (error: any) {
        useToast().add({ title: 'Errors Signing Out', description: error.message })
      }
    },
    toggleModal() {
      this.modalState = !this.modalState
    },

    openModal() {
      this.modalState = true
    },

    closeModal() {
      this.modalState = false
    },

    async updateProfile(props: Partial<iSupabaseProfile>): Promise<void> {
      try {
        if (!this.userId)
          throw new Error('You must be logged in to update your profile')
        const updates = { id: this.userId, ...props }
        const { error } = await useSupabaseClient<SupaDatabase>().from('profiles').upsert(updates)
        if (error)
          throw error
      }
      catch (error: any) {
        useToast().add({ title: 'Errors Updating profile', description: error.message })
      }
    },

    parseNewSession(newSession?: Partial<Session> | null) {
      if (!newSession)
        return this.parseNoSession()
      this.$patch({
        session: { ...newSession },
        token: newSession.access_token,
        userId: newSession?.user?.id,
        email: newSession?.user?.email,
        level: getLevelFromRole(newSession?.user?.app_metadata?.userrole),
        modalState: false,
      })
    },

    parseNoSession() {
      this.$patch({
        userId: undefined,
        username: undefined,
        email: undefined,
        session: undefined,
        level: 0,
        token: undefined,
        sleeperId: undefined,
      })
    },
    async refreshSession() {
      if (!this.isAuthenticated)
        return
      try {
        const { data, error } = await useSupabaseClient().auth.refreshSession()
        this.parseNewSession(data.session)
        if (error)
          throw error
      }
      catch (error: any) {
        useToast().add({ title: 'Errors Updating profile', description: error.message })
      }
    },
    async parseUserSession() {
      const { data } = await fetchUserSession()
      this.parseNewSession(data.session)
    },
    async refreshUserProfile(userId: string, force: boolean = false) {
      if (this.username && this.refreshDate.valueOf() > Date.now() - 1000 * 60 * 60 * 24 && !force)
        return
      const { data } = await fetchUserProfile(userId)
      this.$patch({
        username: data?.username,
        sleeperId: data?.sleeperId,
        refreshDate: new Date(),
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
