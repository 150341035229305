export function getPlayerName({ id_name, id_firstname, id_lastname, id_pff }: iPlayer): string {
  return id_name || (id_lastname ? `${id_firstname || ''} ${id_lastname}` : `Unknown ${id_pff}`)
}

export function getPlayerId(player: string | iPlayer): string {
  return (typeof player === 'string' ? player : player.id_pff!)
}

export function getPlayerIds<T extends iPlayerStringInputs>(players: T): iPlayerStringOutput<T> {
  return (Array.isArray(players) ? players.map(player => getPlayerId(player)) : getPlayerId(players)) as iPlayerStringOutput<T>
}
