export function newToastAlert(title: string, description?: string) {
  useToast().add({ title, description, icon: 'i-line-md-alert', color: 'red' })
}

export class CustomError extends Error {
  title?: string
  description?: string
  help?: string
  constructor(message: string, description?: string, help?: string) {
    super(message)
    this.title = message
    this.description = description
    this.help = help
    this.name = 'Custom Error'
  }

  log() {
    // eslint-disable-next-line no-console
    console.log(`Custom error: ${this.message}`)
  }

  toast() {
    if (this.title)
      newToastAlert(this.title, this.description)
  }
}

const errorStatusCodeDetails = {
  badRequest: {
    title: 'Bad Request',
    description: 'The request was invalid. Please try again.',
  },
  internalError: {
    title: 'Internal Server Error',
    description: 'The server encountered an error. Please try again later.',
  },
  unavailable: {
    title: 'Service Unavailable',
    description: 'The server is not responding. Please try again later.',
  },
  throttle: {
    title: 'Too many requests',
    description: 'You have made too many requests. Please try again later.',
  },
  notFound: {
    title: 'Data not found',
    description: 'The data you are requesting was not found. Please try again later.',
  },
  unauthorized: {
    title: 'Unauthorized',
    description: 'You are not authorized to access this data.',
  },
  forbidden: {
    title: 'Forbidden',
    description: 'You are not allowed to access this data.',
  },
} as const

const errorStatusCodeTypes: Record<number, keyof (typeof errorStatusCodeDetails)> = {
  400: 'badRequest',
  401: 'unauthorized',
  // 402: 'paymentRequired',
  403: 'forbidden',
  429: 'throttle',
  404: 'notFound',
  500: 'internalError',
  502: 'unavailable',
  503: 'unavailable',
  504: 'unavailable',
}

export class HttpError extends Error {
  statusCode: number
  title?: string
  description?: string
  help?: string
  constructor(statusCode: number, message?: string) {
    super(message)
    this.statusCode = statusCode
    const data = this.parseStatusCode(statusCode)
    this.title = data?.title || `Error ${statusCode}`
    this.description = message || data?.description || 'An error occurred'
    this.help = message || data?.title
  }

  parseStatusCode(statusCode: number) {
    const statusCodeType = errorStatusCodeTypes[statusCode]
    if (!statusCodeType)
      return
    return errorStatusCodeDetails[statusCodeType]
  }

  log() {
    // eslint-disable-next-line no-console
    console.log(`Http error: ${this.message}`)
  }

  toast() {
    if (this.title)
      newToastAlert(this.title, this.description)
  }
}

export function handleErrors(error: unknown) {
  if (error instanceof CustomError || error instanceof HttpError) {
    error.log()
    error.toast()
    return error.help
  }
}
