import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  // const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn)
    return

  // if (sentry.environment === 'develop')
  //   return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    // integrations: sentry.environment === 'develop' ? [] : [
    //   new Sentry.BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(router as any),
    //   }),
    //   // new Sentry.Replay(),
    // ],

    tracesSampleRate: 0.2,
    tracePropagationTargets: ['localhost', 'https://dynastydatalab.com', 'https://dynastydatalab.netlify.app'],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  })
})
