import revive_payload_client_isAv5I0SYi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZPScS8eYLe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kd5dv7uCr1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8ILXG9J583 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass@1.79._65b76xe4lq6jjwpdp2ljt7h2ne/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_tzmH66vnPZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_VXQRVeu5F8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uuN7q07A7x from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BI4jp81qnl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3Tatn51GS6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_6lOCeg1nNy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_elQRCe1Kg8 from "/opt/build/repo/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import preview_client_lbKghO4qWB from "/opt/build/repo/node_modules/.pnpm/@nuxthq+studio@2.1.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_client_xugf1kGpui from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_aD8503UMrZ from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass@1.79.5__u42fjrwtutbtn3s6rq5q3z5h4q/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import auth_redirect_7YxV930Bgu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import slideovers_R9wsAcjYYx from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_s_jyaoheci3wpkndzitbfxktmwva/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_0AKedt1V9D from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_s_jyaoheci3wpkndzitbfxktmwva/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_84TVk9H9Bz from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_s_jyaoheci3wpkndzitbfxktmwva/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_rWClN01DQ4 from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass@1.79.5_terse_vitvfngsxxz4oouqsbj6vq62la/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_q1TLR4CgSp from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7._w367bmdhtgrgy63gkt7gwwa7xe/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_rS5xb60Z0v from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_n7tokq7p5orfvfqa7nvbtjgnzi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_ICnigTbIV4 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7._w367bmdhtgrgy63gkt7gwwa7xe/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_I1evIGX3G8 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7._w367bmdhtgrgy63gkt7gwwa7xe/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import floating_zzW5zaa1LU from "/opt/build/repo/plugins/floating.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import variables_M3EiEc4WpY from "/opt/build/repo/plugins/variables.ts";
import plugin_auto_pageviews_client_oNTPhGQHd8 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_isAv5I0SYi,
  unhead_ZPScS8eYLe,
  router_kd5dv7uCr1,
  _0_siteConfig_8ILXG9J583,
  supabase_client_tzmH66vnPZ,
  payload_client_VXQRVeu5F8,
  navigation_repaint_client_uuN7q07A7x,
  check_outdated_build_client_BI4jp81qnl,
  chunk_reload_client_3Tatn51GS6,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6lOCeg1nNy,
  motion_elQRCe1Kg8,
  preview_client_lbKghO4qWB,
  plugin_client_xugf1kGpui,
  floating_vue_EIcJ7xiw0h,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_aD8503UMrZ,
  auth_redirect_7YxV930Bgu,
  slideovers_R9wsAcjYYx,
  modals_0AKedt1V9D,
  colors_84TVk9H9Bz,
  plugin_client_xyy6tkAUk1,
  plugin_rWClN01DQ4,
  scrollbars_client_q1TLR4CgSp,
  plugin_rS5xb60Z0v,
  presets_ICnigTbIV4,
  variables_I1evIGX3G8,
  floating_zzW5zaa1LU,
  sentry_3AyO8nEfhE,
  variables_M3EiEc4WpY,
  plugin_auto_pageviews_client_oNTPhGQHd8
]