// const SidebarPages = new Map<string, iSidebarInputType | Record<string, iSidebarInputType>>([
//   ['adp-id', { startup: 'startup', rookie: 'rookie' }],
//   ['trades-player', 'tradePlayer'],
//   ['trades-user', 'tradeUser'],
//   ['trades-rostership', 'rostership'],
//   ['rankings-id', 'rankings'],
//   ['rankings-view-id', 'rankings'],
//   ['rankings-edit-id', 'rankings'],
// ])

export default defineNuxtRouteMiddleware((to, from) => {
  if (from.path !== to.path)
    useSidebarStore().disableSidebar()

  // const sidebarType = SidebarPages.get(to.name)
  // if (typeof sidebarType === 'string') {
  //   console.log('string')
  //   useSidebarStore().disableSidebar()
  // }
  // // useSidebarStore().setType(sidebarType)
  // else if (typeof sidebarType === 'object' && hasProperty(to.params, 'id') && Object.hasOwnProperty.call(sidebarType, to.params.id)) {
  //   console.log('object')
  //   useSidebarStore().disableSidebar()
  // }
  // // useSidebarStore().setType(sidebarType[to.params.id])
  // else {
  //   console.log(sidebarType)
  //   useSidebarStore().disableSidebar()
  // }
  // useSidebarStore().setType('empty')
})
