export const mapRoleValues = new Map([
  ['master', 10],
  ['admin', 8],
  ['elite', 5],
  ['pro', 3],
  ['user', 1],
  ['guest', 0],
])

const mapRoleNames = new Map([...mapRoleValues.entries()].map(([key, value]) => [value, key]))

export function getRoleFromlevel(level?: unknown): string {
  if (typeof level !== 'number')
    return 'guest'
  return mapRoleNames.get(level) || 'guest'
}

export function getLevelFromRole(role?: unknown): number {
  if (typeof role !== 'string')
    return 1
  return mapRoleValues.get(role) || 1
}

export function getUserLevel(role?: unknown): number {
  return (typeof role === 'string' && mapRoleValues.has(role)) ? (mapRoleValues.get(role) || 1) : 1
}

export async function fetchUserSession() {
  return useSupabaseClient().auth.getSession()
}

export async function fetchUserClaim() {
  return await useSupabaseClient<SupaDatabase>().rpc('get_my_claim', { claim: 'userrole' })
}

export async function fetchUserProfile(userId: string) {
  return useSupabaseClient().from('profiles')
    .select('username, full_name, sleeperId')
    .eq('id', userId)
    .single()
}
