import { defu } from 'defu'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import type { AsyncDataOptions, UseFetchOptions } from '#app'

export function getApiDefaults<T>({ errors = true, auth = false }: iApidefaultOptions = {}) {
  // const apiBaseUrl = useRuntimeConfig().public.api.url
  const defaults: UseFetchOptions<T> = {
    baseURL: 'https://api.dynastydatalab.com/api/',
    // baseURL: `http://127.0.0.1:3026/api/`,
    // baseURL: `http://127.0.0.1:3025/api/`,
  }

  if (auth) {
    defaults.onRequest = ({ options }) => {
      const token = useAuthStore()?.token
      if (token)
        options.headers = { ...options.headers, authorization: `Bearer ${token}` }
    }
  }

  if (errors) {
    defaults.onRequestError = () => { throw new HttpError(400, 'Error on Request') }
    defaults.onResponseError = ({ response }) => { throw new HttpError(response.status, response?._data?.error) }
  }
  return defaults
}

export function useBppFetch<T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
  defaultOptions: iApidefaultOptions = {},
): iFetchState<T> {
  const defaults = getApiDefaults<T>(defaultOptions)
  const params = defu(options, defaults)
  return useFetch<T>(url, params as any) as iFetchState<T>
}
export type KeysOf<T> = Array<T extends T ? keyof T extends string ? keyof T : never : never>

export function useBppAsyncData<T, transformT = T, DefaultT = null, ErrorT = unknown>(
  fetchId: string,
  url: string,
  fetchoptions: NitroFetchOptions<NitroFetchRequest, 'get' | 'head' | 'patch' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace'> = {},
  options: AsyncDataOptions<T, transformT, KeysOf<transformT>, DefaultT> = {},
  defaultFetchOptions: iApidefaultOptions = {},
) {
  const fetchDefaults = getApiDefaults<T>(defaultFetchOptions)
  const params = defu(fetchoptions, fetchDefaults)
  // const optionsDefaults = {}
  const optionsDefaults = { server: false }
  const asyncOptions = defu(optionsDefaults, options)
  return useAsyncData<T, ErrorT, transformT, KeysOf<transformT>, DefaultT >(fetchId, () => $fetch<T>(url, { ...params }), asyncOptions)
}
export function useBppSimpleFetch<T>(
  url: NitroFetchRequest,
  options: NitroFetchOptions<NitroFetchRequest, 'get' | 'head' | 'patch' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace'> = {},
  defaultOptions: iApidefaultOptions = {},
) {
  const defaults = getApiDefaults<T>(defaultOptions)
  const params = defu(options, defaults)
  return $fetch<T>(url, params)
}

export function transformApiFetchedAt<T>(dataset: T): iApiRefresh<T> {
  return { dataset, fetchedAt: new Date() }
}

export function getCachedDataBase(key: string) {
  return useNuxtApp().payload.data[key] || useNuxtApp().static.data[key]
}

export function getCachedDataTTL(key: string, ttl = 60_000) {
  const data = getCachedDataBase(key)
  if (!data)
    return
  const expirationDate = new Date(data.fetchedAt)
  expirationDate.setTime(expirationDate.getTime() + ttl)
  const isExpired = expirationDate.getTime() < Date.now()
  return isExpired ? undefined : data
}

// getCachedData(key) {
//   const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
//   if (!data)
//     return
//   const expirationDate = new Date(data.fetchedAt)
//   expirationDate.setTime(expirationDate.getTime() + 1000 * 60)
//   const isExpired = expirationDate.getTime() < Date.now()
//   return isExpired ? undefined : data
// },
