import type { FilterModel } from 'ag-grid-community'
import bppSidebarSettings from '~/config/SideBar.settings'

const sidebarTypeMap = new Map<iSidebarInputType, string>([
  ['tradePlayer', 'empty'],
  ['tradeUser', 'empty'],
  // ['tradePlayer', 'trade'],
  // ['tradeUser', 'trade'],
  ['rostership', 'rostership'],
  ['underdogRostership', 'underdogRostership'],
  ['underdogAdp', 'underdogAdp'],
  ['startup', 'adp'],
  ['sfb', 'sfb'],
  ['rankings', 'rankings'],
  ['rookie', 'adp'],
  ['adpComp', 'adp'],
  ['draft', 'draft'],
  ['empty', 'empty'],
])

export const useSidebarStore = defineStore({
  id: 'SidebarStore',
  state: (): iSidebarStoreState => ({
    type: 'startup',
    status: false,
    filters: new Map(),
    highlight: new Map(),
    highlightedTeam: undefined,
    heavyFade: new Map(),
    states: {},
    showSidebar: false,
    filterModels: {},
    filterSettings: {},
    gridApi: {},
    dataset: [],
  }),

  getters: {
    isHighlighted(state): boolean { return state.highlight.size > 0 },
    isHeavyFaded(state): boolean { return state.heavyFade.size > 0 },
    isSidebarOpen(state): boolean { return state.showSidebar },
    isSidebarDisabled(state): boolean { return !state.status },
    getFilteredPlayers(state): Set<string> {
      return new Set([...state.filters.entries()].reduce((acc, [item, value]) => {
        if (value.size > 0)
          acc.push(item)
        return acc
      }, [] as string[]))
    },
    getType(state): iSidebarSettings {
      return bppSidebarSettings.get(state.type) || { name: 'empty' }
    },
    getBaseType(state): string {
      return sidebarTypeMap.get(state.type) || 'empty'
    },
    getModelType(): iSidebarFilterType {
      return this.getType.filterType || 'empty'
    },
    getItemSettings(state): Array<[string, iSidebarMetricConfig]> {
      return [...Object.entries(state.filterSettings)].filter(([_, { disabled }]) => !disabled)
    },
  },

  actions: {
    toggleSideBar(): void {
      this.showSidebar = !this.showSidebar
    },

    openSidebar(): void {
      this.showSidebar = true
    },

    closeSidebar(): void {
      this.showSidebar = false
    },

    initializeFilters<T extends Record<string, any>>(type: iSidebarInputType, dataset: T[], extraDataset: Partial<Record<keyof T, number[]>> = {}): void {
      this.$patch((state) => {
        state.type = type
        state.dataset = dataset
        state.filterSettings = this.genNewFilterSettings<T>(dataset, extraDataset)
        state.filters = this.genNewFilters<T>(dataset)
        state.states = genSidebarStates(this.dataset)
        state.filterModels = this.genNewFilterModels()
        state.highlight = new Map()
        state.heavyFade = new Map()
        state.highlightedTeam = undefined
        state.status = true
      })
    },

    resetFilters(): void {
      this.$patch((state) => {
        state.filters = this.genNewFilters()
        state.filterModels = this.genDefaultFilterModels()
        state.states = genSidebarStates(this.dataset)
        state.highlight = new Map()
        state.heavyFade = new Map()
        state.highlightedTeam = undefined
        this.removeAllGridFilters()
      })
    },

    genNewFilters<T extends Record<string, any>>(dataset?: T[]): Map<string, Set<any>> {
      const newFilters = new Map()
      if (!dataset || dataset.length === 0) {
        for (const key of this.filters.keys())
          newFilters.set(key, new Set())
      }
      else {
        const keyToUse = getSidebarDatasetKey(dataset)
        for (const player of dataset)
          newFilters.set(player[keyToUse], new Set())
      }
      return newFilters
    },

    genNewFilterSettings<T>(dataset: T[], extraDataset: Partial<Record<keyof T, number[]>> = {}): Record<string, any> {
      const newFilterSettings: Record<string, any> = {}
      const settings = bppSidebarSettings.get(this.type)
      if (!settings)
        return newFilterSettings
      const modelType = settings?.filterType || 'empty'
      const newSettings = [...settings?.metrics?.entries() ?? []]
      for (const [key, value] of newSettings) {
        if (value.field === undefined)
          continue
        const extraSettings = getExtraSidebarInfo(modelType, value.inputFilter, dataset, value.field as keyof T, extraDataset)
        newFilterSettings[key] = { ...value, ...extraSettings }
      }
      return newFilterSettings
    },

    genNewFilterModels(): Record<string, any> {
      const newFilterModels: Record<string, any> = {}
      for (const [key, value] of Object.entries(this.filterSettings)) {
        if (value?.defaultValue === undefined)
          continue
        newFilterModels[key] = JSON.parse(JSON.stringify(value.defaultValue))
      }
      return newFilterModels
    },

    genDefaultFilterModels() {
      const newFilterModels: Record<string, any> = {}
      for (const filter of Object.keys(this.filterModels))
        newFilterModels[filter] = JSON.parse(JSON.stringify(this.filterSettings[filter].defaultValue))
      return newFilterModels
    },

    setState(item: string, state: iSidebarDataState, newState: boolean): void {
      const itemState = this.states[item]
      if (itemState && itemState[state] !== newState)
        itemState[state] = newState
    },

    setFilter<T extends Record<string, any>>(attribute: string, condition: (element: T) => boolean): void {
      const keyToUse = getSidebarDatasetKey(this.dataset)
      for (const player of this.dataset) {
        const playerKey = player[keyToUse]
        const currentSet = this.filters.get(playerKey)
        if (!currentSet)
          continue
        condition(player as T) ? currentSet.delete(attribute) : currentSet.add(attribute)
        this.setState(playerKey, 'fade', currentSet.size > 0)
      }
    },

    setGridFilter(attribute: string, filter: FilterModel): void {
      for (const api of Object.values(this.gridApi)) {
        const model = api.getFilterModel()
        model[attribute] = filter
        api.setFilterModel(model)
        api.onFilterChanged()
      }
    },

    removeGridFilter(attribute: string): void {
      for (const api of Object.values(this.gridApi)) {
        const model = api.getFilterModel()
        delete model[attribute]
        api.setFilterModel(model)
        api.onFilterChanged()
      }
    },

    removeAllGridFilters(): void {
      for (const api of Object.values(this.gridApi))
        api.setFilterModel(null)
      // api.onFilterChanged()
    },

    removeFilter(attribute: string): void {
      for (const [playerKey, playerSet] of this.filters.entries()) {
        playerSet.delete(attribute)
        this.setState(playerKey, 'fade', false)
      }
    },

    // for (const player of this.dataset) {
    //   const playerKey = player[keyToUse]
    //   const currentSet = this.filters.get(playerKey)
    //   if (!currentSet)
    //     continue
    //   condition(player as T) ? currentSet.delete(attribute) : currentSet.add(attribute)
    //   this.setState(playerKey, 'fade', currentSet.size > 0)
    // }
    addSidebarMetricValue(dataset: string[], attribute: string, metric: iSidebarMetric): void {
      const metricSet = this[metric]
      for (const item of dataset) {
        const itemAttributes = metricSet.get(item)
        if (itemAttributes) {
          itemAttributes.add(attribute)
        }
        else {
          this.setState(item, metric, true)
          metricSet.set(item, new Set([attribute]))
        }
      }
    },

    removeSidebarMetricValue(dataset: string[], attribute: string, metric: iSidebarMetric): void {
      const metricSet = this[metric]
      for (const item of dataset) {
        const itemAttributes = metricSet.get(item)
        if (itemAttributes) {
          if (itemAttributes.size === 1) {
            metricSet.delete(item)
            this.setState(item, metric, false)
          }
          else {
            itemAttributes.delete(attribute)
          }
        }
      }
    },

    removeSidebarMetric(attribute: string, metric: iSidebarMetric): void {
      const metricSet = this[metric]
      for (const [key, attributes] of metricSet.entries()) {
        if (attributes.size === 1) {
          metricSet.delete(key)
          this.setState(key, metric, false)
        }
        else {
          attributes.delete(attribute)
        }
      }
    },

    replaceSidebarMetricValue(dataset: string[], attribute: string, metric: iSidebarMetric): void {
      this.removeSidebarMetric(attribute, metric)
      this.addSidebarMetricValue(dataset, attribute, metric)
    },

    modifyMetric(action: iSidebarMetricActionType, metric: iSidebarMetric, attribute: string, value?: string | string[]): void {
      // const tempMetric = new Map(this[metric])
      const dataset = Array.isArray(value) ? value : (value ? [value] : [])
      const actions = {
        add: () => this.addSidebarMetricValue(dataset, attribute, metric),
        remove: () => this.removeSidebarMetricValue(dataset, attribute, metric),
        removeAll: () => this.removeSidebarMetric(attribute, metric),
        replace: () => this.replaceSidebarMetricValue(dataset, attribute, metric),
      } as const
      actions[action]()
      // this.$patch((state) => {
      //   state[metric] = tempMetric
      // })
    },

    disableSidebar(): void {
      this.$patch((state) => {
        state.status = false
        state.showSidebar = false
      })
    },

    enableSidebar(): void {
      this.$patch({ status: true })
    },

    setType(type: iSidebarInputType = 'empty'): void {
      if (type !== this.type)
        this.$patch({ type })
    },

    setnewSettings(model: string, newSettings: Record<string, any>): void {
      if (!Object.hasOwnProperty.call(this.filterSettings, model))
        return
      this.$patch((state) => {
        state.filterSettings[model] = { ...state.filterSettings[model], ...newSettings }
      })
    },

  },
})

export default useSidebarStore

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSidebarStore, import.meta.hot))
