const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _slate = {"50":"#f8fafc","100":"#f1f5f9","200":"#e2e8f0","300":"#cbd5e1","400":"#94a3b8","500":"#64748b","600":"#475569","700":"#334155","800":"#1e293b","900":"#0f172a","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#fef2f2","100":"#fbebeb","200":"#f7d6d6","300":"#f2c2c2","400":"#eeadad","500":"#ea9999","600":"#bb7a7a","700":"#8c5c5c","800":"#5e3d3d","900":"#2f1f1f","950":"#450a0a"}
const _orange = {"50":"#fff7ed","100":"#ffedd5","200":"#fed7aa","300":"#fdba74","400":"#fb923c","500":"#f97316","600":"#ea580c","700":"#c2410c","800":"#9a3412","900":"#7c2d12","950":"#431407"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = {"50":"#fefce8","100":"#fffaeb","200":"#fff5d6","300":"#ffefc2","400":"#ffeaad","500":"#ffe599","600":"#ccb77a","700":"#99895c","800":"#665c3d","900":"#332e1f","950":"#422006"}
const _lime = {"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}
const _green = {"50":"#f0fdf4","100":"#f0f7ee","200":"#e2efdc","300":"#d3e7cb","400":"#c5dfb9","500":"#b6d7a8","600":"#92ac86","700":"#6d8165","800":"#495643","900":"#242b22","950":"#052e16"}
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = {"50":"#f0fdfa","100":"#ccfbf1","200":"#99f6e4","300":"#5eead4","400":"#2dd4bf","500":"#14b8a6","600":"#0d9488","700":"#0f766e","800":"#115e59","900":"#134e4a","950":"#042f2e"}
const _cyan = {"50":"#ecfeff","100":"#cffafe","200":"#a5f3fc","300":"#67e8f9","400":"#22d3ee","500":"#06b6d4","600":"#0891b2","700":"#0e7490","800":"#155e75","900":"#164e63","950":"#083344"}
const _sky = {"50":"#f0f9ff","100":"#e0f2fe","200":"#bae6fd","300":"#7dd3fc","400":"#38bdf8","500":"#0ea5e9","600":"#0284c7","700":"#0369a1","800":"#075985","900":"#0c4a6e","950":"#082f49"}
const _blue = {"50":"#eff6ff","100":"#ecf3fa","200":"#d9e8f6","300":"#c5dcf1","400":"#b2d1ed","500":"#9fc5e8","600":"#7f9eba","700":"#5f768b","800":"#404f5d","900":"#20272e","950":"#172554"}
const _indigo = {"50":"#eef2ff","100":"#e0e7ff","200":"#c7d2fe","300":"#a5b4fc","400":"#818cf8","500":"#6366f1","600":"#4f46e5","700":"#4338ca","800":"#3730a3","900":"#312e81","950":"#1e1b4b"}
const _violet = {"50":"#f5f3ff","100":"#e6e1ee","200":"#cdc2dd","300":"#b3a4cb","400":"#9a85ba","500":"#8167a9","600":"#675287","700":"#4d3e65","800":"#342944","900":"#1a1522","950":"#2e1065"}
const _purple = {"50":"#faf5ff","100":"#ececfa","200":"#d9d9f6","300":"#c5c7f1","400":"#b2b4ed","500":"#9fa1e8","600":"#7f81ba","700":"#5f618b","800":"#40405d","900":"#20202e","950":"#3b0764"}
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = {"50":"#fdf2f8","100":"#fce7f3","200":"#fbcfe8","300":"#f9a8d4","400":"#f472b6","500":"#ec4899","600":"#db2777","700":"#be185d","800":"#9d174d","900":"#831843","950":"#500724"}
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _background = "#020617"
const _foreground = "rgb(var(--ui-foreground) / <alpha-value>)"
const _bg = "#2E2E33"
const _textWhite = "#A9B6BC"
const _bgContrast = "#464E56"
const _bgContrastDeep = "#6C757D"
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _primaryNew = "#7C4DFF"
const _primaryContrast = "#9A85BA"
const _default = "#CCCCCC"
const _defaultDark = "#343A40"
const _defaultDarkContrast = "#313D3D"
const _defaultLight = "#EFEFEF"
const _defaultLightContrast = "#CCCCCC"
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "background": _background, "foreground": _foreground, "bg": _bg, "textWhite": _textWhite, "bgContrast": _bgContrast, "bgContrastDeep": _bgContrastDeep, "primary": _primary, "primaryNew": _primaryNew, "primaryContrast": _primaryContrast, "default": _default, "defaultDark": _defaultDark, "defaultDarkContrast": _defaultDarkContrast, "defaultLight": _defaultLight, "defaultLightContrast": _defaultLightContrast, "cool": _cool,  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _background, _foreground, _bg, _textWhite, _bgContrast, _bgContrastDeep, _primary, _primaryNew, _primaryContrast, _default, _defaultDark, _defaultDarkContrast, _defaultLight, _defaultLightContrast, _cool }