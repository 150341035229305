<script setup lang="ts">
const AuthStore = useAuthStore()
const { modalState, isAuthenticated } = storeToRefs(AuthStore)
</script>

<template>
  <LazyAccountAuth v-if="isAuthenticated" />
  <div v-else>
    <UButton v-if="isLargeScreen" :ui="{ rounded: 'rounded-full' }" color="primary" variant="outline" label="Sign In" @click="AuthStore.openModal()" />
    <UButton v-else color="primary" icon="i-material-symbols-login" square variant="outline" @click="AuthStore.openModal()" />
    <UModal v-model="modalState" :appear="false" :ui="{ container: 'flex min-h-full items-center justify-center text-center' }">
      <LoginAuth />
    </UModal>
  </div>
</template>
