export const usePlayerDataStore = defineStore({
  id: 'PlayerDataStore',
  persist: true,
  state: (): iPlayerDataStoreState => ({
    adpType: 'startup',
    adpData: [],
    playerData: [],
    refreshDate: undefined,
    isLoading: false,
    numberTeams: 12,
    playerModalId: undefined,
    playerMap: {
      id_sleeper: new Map(),
      id_pff: new Map(),
      id_ud: new Map(),
      id_dk: new Map(),
    },
  }),
  getters: {
  },
  actions: {
    isStringPlayerId(playerId: unknown, origin: iPlayerStringOrigin = 'id_sleeper'): playerId is string {
      return typeof playerId === 'string' && this.playerMap[origin].has(playerId)
    },
    getPlayerData(playerId: string | iPlayer, origin: iPlayerStringOrigin = 'id_sleeper'): iPlayer | undefined {
      if (typeof playerId === 'object')
        return playerId
      const player = this.playerMap[origin].get(playerId)
      if (player)
        return player
      return this.getPickData(playerId)
    },
    getPickData(pickId: string, currentSeason = 2023): iPlayer | undefined {
      const pickData = parsePickId(pickId, currentSeason)
      if (!pickData)
        return
      const { id_player, pickName, season, round } = pickData
      return {
        draft_round: round,
        draft_year: season,
        id_firstname: '',
        id_lastname: pickName,
        id_name: pickName,
        id_pff: id_player,
        id_pos: 'PK',
        id_sleeper: id_player,
        id_team: undefined,
        phy_age: undefined,
        pick_perc: 0,
      }
    },
    genArrayPlayerData(
      dataset: string[],
      origin: iPlayerStringOrigin = 'id_sleeper',
      sortAttribute: iPlayerNumeric = 'adp_adp',
    ): iPlayer[] {
      const data = dataset.map(playerId => this.getPlayerData(playerId, origin)).filter(Boolean) as iPlayer[]
      if (sortAttribute !== undefined)
        data.sort(sortByAttribute<iPlayer>(sortAttribute))
      return data
    },
    getPlayerMetrics<T extends { [K in iPlayerStringOrigin]?: string }>(
      dataset: Array<T>,
      origin: iPlayerStringOrigin = 'id_sleeper',
      metrics: Array<keyof iPlayer> = [
        'id_pff',
        'id_ud',
        'id_pos',
        'id_firstname',
        'id_lastname',
        'draft_year',
        'draft_round',
        'phy_age',
        'id_team',
      ],
    ) {
      const playerMap = this.playerMap[origin]
      return dataset.map((player) => {
        const { [origin]: playerId } = player
        if (!playerId)
          return player
        const metricsData: Partial<iPlayer> | undefined = playerMap.get(playerId)
        if (!metricsData)
          return player
        const metricData: Partial<iPlayer> = {}
        for (const metric of metrics) {
          if (Object.prototype.hasOwnProperty.call(metricsData, metric)) {
            const value = metricsData[metric]
            if (value !== undefined)
              (metricData[metric] as typeof value) = value
          }
        }
        return { ...player, ...metricData }
      })
    },
    patchPlayerMetrics(
      data: iPlayer[],
      metrics: (keyof iPlayer)[] = [
        'id_pff',
        'id_mfl',
        'id_ud',
        'id_pos',
        'id_firstname',
        'id_lastname',
        'draft_year',
        'draft_round',
        'phy_age',
        'id_team',
      ],
      origin: iPlayerStringOrigin = 'id_sleeper',
    ): void {
      const playerMap = this.playerMap[origin]
      for (const player of data) {
        const { [origin]: playerId } = player
        if (!playerId)
          continue
        const metricsData: Partial<iPlayer> | undefined = playerMap.get(playerId)
        if (!metricsData)
          continue
        for (const metric of metrics) {
          if (Object.prototype.hasOwnProperty.call(metricsData, metric)) {
            const value = metricsData[metric]
            if (value !== undefined)
              (player[metric] as typeof value) = value
          }
        }
      }
    },
    addPlayerNames(players: iPlayer[]) {
      for (const player of players) {
        const { id_firstname, id_lastname, phy_age } = player
        const hasLastName = Boolean(id_lastname)
        player.id_name = hasLastName ? (id_firstname ? `${id_firstname} ${id_lastname}` : id_lastname) : 'Unknown'
        player.id_shortname = hasLastName ? (id_firstname ? `${id_firstname[0]}. ${id_lastname}` : id_lastname) : 'Unknown'
        player.phy_age = convertStringToNumber(phy_age)
      }
      return players
    },
    generatePlayerMaps(playerData: iPlayer[]) {
      const playerMap = {
        id_sleeper: new Map(playerData.map((player) => { return [player.id_sleeper, player] })),
        id_pff: new Map(playerData.map((player) => { return [player.id_pff!, player] })),
        id_dk: new Map(playerData.filter(player => player.id_dk).map((player) => { return [player.id_dk!, player] })),
        id_ud: new Map(playerData.filter(player => player.id_ud).map((player) => { return [player.id_ud!, player] })),
      }
      this.$patch({ playerMap })
    },
    async refreshPlayerData(force = false) {
      if (!force && this.playerData.length > 0 && this.refreshDate && (Date.now() - this.refreshDate) < 2 * 60 * 60 * 2) {
        this.generatePlayerMaps(this.playerData)
        return
      }
      try {
        const players = await import('~/assets/json/players.json')
        const playerList = players.default as unknown as iPlayer[]
        const playerData = this.addPlayerNames(playerList)
        this.generatePlayerMaps(playerData)
        this.$patch({
          playerData,
          refreshDate: Date.now(),
        })
      }
      catch (error: any) {
        useToast().add({ title: 'error Fetching PlayerData', description: error.message })
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePlayerDataStore, import.meta.hot))
