export function getRangeInfo(dataset?: number[]): iSliderRangeProps {
  if (!dataset || dataset.length === 0)
    return { min: 0, max: 0, step: 0, currentValue: [0, 100], defaultValue: [0, 100], disabled: true }
  const range = [...new Set(dataset)].sort((a, b) => a - b)
  if (range.length <= 1)
    return { min: 0, max: 0, step: 0, currentValue: [0, 100], defaultValue: [0, 100], disabled: true }
  const min = Number(range[0])
  const max = Number(range[range.length - 1] ?? 0)
  const step = getStep(range)
  return { min, max, step, currentValue: [min, max], defaultValue: [min, max], disabled: false }
}

function getStep(range: number[]): number {
  if (range.length < 2)
    return 0
  let minStep = Number.POSITIVE_INFINITY
  for (const [i, val] of range.entries()) {
    if (i === 0)
      continue
    const diff = val - range[i - 1]
    if (Number.isNaN(diff))
      throw new Error('Invalid number in range array')
    minStep = Math.min(minStep, diff)
  }
  return Number(minStep.toFixed(2))
}

export function extractDataset<T>(data: T[], metric: keyof T) {
  const values = data.map(item => item[metric])
  return values.filter(val => val !== null && val !== undefined) as number[]
}

export function getExtraSidebarInfo<T>(type: string, inputType: string, dataset: T[], key: keyof T, extraDataset: Partial<Record<keyof T, number[]>> = {}) {
  if (inputType === 'slider') {
    const isRankingMetric = type === 'rankBoard' && typeof key === 'string' && ['tier', 'cluster', 'action'].includes(key)
    const data = isRankingMetric ? extraDataset?.[key] : extractDataset<T>(dataset, key)
    return getRangeInfo(data)
  }
  return { disabled: false }
}

export function getSidebarDatasetKey(dataset: Record<string, any>[]): string {
  return Object.keys(dataset[0]).includes('id_transaction') ? 'id_transaction' : 'id_sleeper'
}

export function genSidebarDataState(): iSidebarDataStates {
  const sidebarDataStates: iSidebarDataState[] = ['fade', 'heavyFade', 'highlight', 'hidden']
  return sidebarDataStates.reduce((acc, val) => ({ ...acc, [val]: false }), {} as iSidebarDataStates)
}

export function genSidebarStates<T extends Record<string, any>>(dataset?: T[]): iSidebarStates {
  const SidebarStates: iSidebarStates = {}
  if (!dataset || dataset.length === 0)
    return SidebarStates
  const keyToUse = getSidebarDatasetKey(dataset)
  for (const player of dataset)
    SidebarStates[player[keyToUse]] = { fade: false, heavyFade: false, highlight: false, hidden: false }
  return SidebarStates
}
